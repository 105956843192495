import { useRef, useState, useEffect, useContext } from 'react';
import { Context } from "./context";
import FuseUtils from '@fuse/utils';
import useAuthContext from 'src/app/AuthContext';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Settings } from './store/Settings.ts';
import { Roles, ROLES } from './store/Roles.ts';
import { Operation } from './store/PermissionSets.ts';

function Provider({children}) {

  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(!isMobile);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [pageHeading, setPageHeading] = useState('Settings');
  const {currentUser } = useAuthContext();
  const [ SettingsManager ] = useState(new Settings());

  if(SettingsManager == null ){
    return (<FuseLoading />);
  }

  const state = ({
    isLeftSidebarOpen, setIsLeftSidebarOpen, isRightSidebarOpen, setIsRightSidebarOpen,
    pageHeading, setPageHeading,
    currentUser,
    SettingsManager, 
    DocumentTypes: SettingsManager.documentTypes,
    JobTitles: SettingsManager.jobTitles,
    Departments: SettingsManager.departments,
    Locations: SettingsManager.locations,
    Residents: SettingsManager.residents,
    Users: SettingsManager.users,
    Roles: SettingsManager.roles, ROLES: ROLES,
    PermissionSets: SettingsManager.permissionSets,
    PermissionSettings: SettingsManager.permissionSettings,
    Operation: Operation
  })
  return (<Context.Provider value={state}>{children}</Context.Provider>)
}
export default Provider;