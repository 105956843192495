import { makeAutoObservable } from "mobx";
import { v4 as uuid } from 'uuid';
import { Role, Roles, ROLES } from "./Roles.ts";

// TODO: Figure out how to handle operations on self versus others
export enum Operation {    
    Approve = 'Approve',
    ApproveOthers = 'ApproveOthers',
    Comment = 'Comment',
    Create = 'Create',
    Delete = 'Delete',
    Download = 'Download',
    Export = 'Export',
    Import = 'Import',
    Print = 'Print',
    Read = 'Read',
    Reject = 'Reject',
    Review = 'Review',
    Share = 'Share',
    Submit = 'Submit',
    Update = 'Update',
    Upload = 'Upload',
    View = 'View',
    Edit = 'Edit',
}

export class Permission {
    id: string;
    name: string;
    command: string;
    operation: string;
    description: string;
    value: boolean = false;
    //createdOn: string;
    //createdBy: string;
    //modifiedOn: string;
    //modifiedBy: string;
    
    constructor() {                
        makeAutoObservable(this);
    }
}

export class PermissionSet {
    items: Permission[] | null;
    id: string;
    name: string;
    description: string;
    //assignment.createdOn = permission.createdOn;
    //assignment.createdBy = permission.createdBy;
    //assignment.modifiedOn = permission.modifiedOn;
    //assignment.modifiedBy = permission.modifiedBy;

    constructor() {
        makeAutoObservable(this);
    }

    addPermission(operation: Operation, name: string, command: string, description: string, value?: boolean, id?: string ){

        const permission = new Permission();
        permission.id = id || uuid();
        permission.operation = operation;
        permission.name = name;
        permission.command = command;
        permission.description = description;
        permission.value = value || false;

        if(this.items == null) this.items = [];
        this.items.push(permission);
    }
}

export class PermissionSets {
    items: PermissionSet[] = [];
    scope: string;
    //assignment.createdOn = permission.createdOn;
    //assignment.createdBy = permission.createdBy;
    //assignment.modifiedOn = permission.modifiedOn;
    //assignment.modifiedBy = permission.modifiedBy;

    constructor(scope?: string, role?: Role) {
        this.scope = scope;

        if(role == null){
            loadPermissions(this);
        }
        else{
           //this.items = createViewModel(role.permissions);
        }
        
        makeAutoObservable(this);
    }

    addPermissionSet(name: string, description: string, id: string | undefined){
        const permissionSet = new PermissionSet();
        permissionSet.name = name;
        permissionSet.description = description;
        permissionSet.id = id || uuid();
        this.items.push(permissionSet);
        return permissionSet;
    }
}

function loadPermissions(sets: PermissionSets) {

    let set = sets.addPermissionSet('Library', 'Library permissions', uuid());
    set.addPermission(Operation.Create, 'Create new library', 'canCreateLibrary', '');
    set.addPermission(Operation.View, 'See library content', '', '');
    set.addPermission(Operation.Update, 'Update library settings', '', '');
    set.addPermission(Operation.Delete, 'Delete libraries', '', '');
    set.addPermission(Operation.Share, 'Share libraries', '', '');
    
    
    set = sets.addPermissionSet('Content', 'Content permissions', uuid());
    set.addPermission(Operation.Create, 'Create new content', 'canCreateContent', '');
    set.addPermission(Operation.View, 'Read content', '', '');
    set.addPermission(Operation.Update, 'Update content', '', '');
    set.addPermission(Operation.Delete, 'Delete content', '', '');
    set.addPermission(Operation.Share, 'Share content', '', '');
    set.addPermission(Operation.Edit, 'Can edit content of others', '', '');
    
    set = sets.addPermissionSet('Forms', 'Forms permissions', uuid());
    set.addPermission(Operation.Create, 'Create new content', 'canCreateContent', '');
    set.addPermission(Operation.View, 'Read content', '', '');
    set.addPermission(Operation.Update, 'Update content', '', '');
    set.addPermission(Operation.Delete, 'Delete content', '', '');
    set.addPermission(Operation.Share, 'Share content', '', '');
    
    set = sets.addPermissionSet('Approval', 'Approval permissions', uuid());
    set.addPermission(Operation.Create, 'Create new content', 'canCreateContent', '');
    set.addPermission(Operation.View, 'Read content', '', '');
    set.addPermission(Operation.Update, 'Update content', '', '');
    set.addPermission(Operation.Delete, 'Delete content', '', '');
    set.addPermission(Operation.Share, 'Share content', '', '');

    set = sets.addPermissionSet('Workflow', 'Workflow permissions', uuid());
    set.addPermission(Operation.Create, 'Create new content', 'canCreateContent', '');
    set.addPermission(Operation.View, 'Read content', '', '');
    set.addPermission(Operation.Update, 'Update content', '', '');
    set.addPermission(Operation.Delete, 'Delete content', '', '');
    set.addPermission(Operation.Share, 'Share content', '', '');
    
    set = sets.addPermissionSet('User Management', 'User management permissions', uuid());
    set.addPermission(Operation.Create, 'Create new content', 'canCreateContent', '');
    set.addPermission(Operation.View, 'Read content', '', '');
    set.addPermission(Operation.Update, 'Update content', '', '');
    set.addPermission(Operation.Delete, 'Delete content', '', '');
    set.addPermission(Operation.Share, 'Share content', '', '');
  
}

/*
    {Feature: 'DocHub', PermissionSet: 'Library', Permission: 'Create library', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Library', Permission: 'Delete library', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Library', Permission: 'View library', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Documents', Permission: 'Create document', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Documents', Permission: 'Edit document of others', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Documents', Permission: 'Edit document of self', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Documents', Permission: 'Delete document', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Documents', Permission: 'View document of self', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Documents', Permission: 'View document of others', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    
    {Feature: 'DocHub', PermissionSet: 'Approval', Permission: 'Can approve content submitted by others', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Approval', Permission: 'Can approve content submitted by self', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Approval', Permission: 'Can customize approval settings', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Approval', Permission: 'Can create approval settings', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Approval', Permission: 'Can delete approval settings', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Approval', Permission: 'Can view approval settings', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},

    {Feature: 'DocHub', PermissionSet: 'Workflow', Permission: 'Can customize workflows', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Workflow', Permission: 'Can create workflows', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Workflow', Permission: 'Can delete workflows', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
    {Feature: 'DocHub', PermissionSet: 'Workflow', Permission: 'Can view workflows settings', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
  
*/

