import { makeAutoObservable } from "mobx";

interface Address {
    street: string;
    city: string;
    region: string;
    postalCode: string;
    country: string;
}

export class User {
    id: string;
    isStaff: boolean;
    firstName: string;
    lastName: string;
    title: string;
    titleOfCourtesy?: string;
    birthDate?: Date;
    hireDate?: Date;
    address?: Address;
    homePhone?: string;
    extension?: string;
    notes?: string;
    reportsTo?: number;
    departments?: string[];
    locations?: string[];
    isActive: boolean;
    
    constructor() {
        makeAutoObservable(this);
    }
}

export class Users {
    items: User[] = [];

    constructor() {
        loadUsers(this);
        makeAutoObservable(this);
    }
}

/**
 * This is temporary and needs to change to a real API call
 */
function loadUsers(users: Users) {
    users.items = [
        {
            id: '1',
            isStaff: true,
            firstName: 'John',
            lastName: 'Doe',
            title: 'Care and Wellness Director',
            isActive: true,
            departments: ['Care and Wellness'],
            locations: ['Main'],
            address: {
                street: '123 Main St',
                city: 'Anytown',
                region: 'CA',
                postalCode: '12345',
                country: 'USA'
            }
        }
    ];
}
