import { matchRoutes } from 'react-router-dom';
import withRouter from './utils/withRouter';
import FuseUtils from '@fuse/utils';
import {
  getSessionRedirectUrl,
  setSessionRedirectUrl,
  resetSessionRedirectUrl,
} from '@fuse/core/FuseAuthorization/sessionRedirectUrl';
import { Component } from 'react';
import history from '@history';

// 
const ignoredPaths = ['__', '/sign-in', '/sign-in', '/signin/microsoft', '/signin/conference', '/sign-in/microsoft', '/sign-in/conference', '/fastpass', '/fpin', '/fpout', '/fpoutpw', '/survey', '/sign-out', '/_test', '/register', '/register/test', '/sign-up', '/logout', '/404', '/reset-password', '/email-confirmed', '/forgot-password', '/manage-account', '/cancel-reset-password'];


class SecureRouter extends Component {

  constructor(props) {
    super(props);
    const { routes } = props;

    this.state = {
      accessGranted: true,
      routes
    };
  }

  componentDidMount() {
    const { accessGranted } = this.state;
		if (!accessGranted) {
			this.redirectRoute();
		}
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { accessGranted } = this.state;
		return nextState.accessGranted !== accessGranted;
  }

  componentDidUpdate() {
    const { accessGranted } = this.state;
		if (!accessGranted) {
			this.redirectRoute();
		}
  }

  
  static getDerivedStateFromProps(props, state) {
    const { location, currentUser } = props;
    let pathname = location?.pathname;
    
    //console.log(`pathname: ${pathname}`);

    if(pathname == "/" || pathname.toLowerCase().indexOf("/sign-in") > -1) {      
      if(currentUser){                
        let redirectUrl = getSessionRedirectUrl(); 
        redirectUrl = (redirectUrl != null)? redirectUrl : currentUser?.data?.Homepage || "/Demo"; // Free until phased out...then /Demo?
        pathname = redirectUrl;
        setTimeout(() => history.push(redirectUrl), 0);
        resetSessionRedirectUrl();
      }
    }
    else {
      if(!currentUser){
        if(!ignoredPaths.includes(pathname.toLowerCase()) && pathname != '/' && pathname.toLowerCase() != '/sign-in' && pathname.toLowerCase() != '/sign-in/microsoft/'){          
          setSessionRedirectUrl(pathname);
          return { accessGranted: false };  // make sure user is logged in
        }
      }
    }

    //const auth = getFuseRouteParamUtil(pathname, 'auth', false);

    const matchedRoutes = matchRoutes(state.routes, pathname);
    const matched = matchedRoutes ? matchedRoutes[0] : false;
    const Roles = (!currentUser?.data?.Roles)? [] : currentUser?.data?.Roles;
    const userHasPermission = FuseUtils.hasPermission(matched.route.auth, Roles);

    if (matched && !userHasPermission && !ignoredPaths.includes(pathname)) {
      if(pathname != '/' && pathname.toLowerCase() != '/sign-in') {
        setSessionRedirectUrl(pathname);
      }      
    }

    return {
      accessGranted: matched ? userHasPermission : true,
    };

  } // getDerivedStateFromProps

  redirectRoute() {
    const { currentUser } = this.props;
    const redirectUrl = getSessionRedirectUrl() || currentUser?.data?.Homepage || this.props.loginRedirectUrl;

    /*
        User is guest
        Redirect to Login Page
        */
    const userRole = (!currentUser?.data?.Roles)? [] : currentUser?.data?.Roles;
    if (!userRole || userRole.length === 0) {
      setTimeout(() => history.push('/Sign-in'), 0);
    } 
    else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or loginRedirectUrl
        */
      setTimeout(() => history.push(redirectUrl), 0);

      resetSessionRedirectUrl();
    }
  }

  render() {
    // console.info('Fuse Authorization rendered', this.state.accessGranted);
    return this.state.accessGranted ? this.props.children : null;
  }

}

export default withRouter(SecureRouter);