import { makeAutoObservable } from "mobx";
import { PermissionSets } from "./PermissionSets.ts";

export enum RoleName {
    Admin = 'Admin',
    Owner = 'Owner',
    Approver = 'Approver',
    Editor = 'Editor',
    Contributor = 'Contributor',
    Viewer = 'Viewer',
    Auditor = 'Auditor',
    Guest = 'Guest',
    NoAccess = 'No Access',
    Anonymous = 'Anonymous'
}

export class Role {
    id: string;
    name: string;    
    description: string;
    permissions: null | PermissionSets  = null;

    constructor(id: string, name: string, description: string, permissions: PermissionSets) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.permissions = permissions;
        makeAutoObservable(this);
    }
}

class RolesStore {
    items: Role[] = [];

    constructor() {
        loadRoles(this);
        makeAutoObservable(this);
    }

    add(id: string, name: string, description: string, permissions: PermissionSets) {
        const role = new Role(id, name, description, permissions);
        this.items.push(role);
    }

    getRoleById(id: string) {
        return this.items.find(item => item.id === id);
    }

    getRoleByName(name: string) {
        let role = this.items.find(item => item.name.toLowerCase() === name.toLowerCase());
        return role;
    }
}

export const Roles = new RolesStore();

function loadRoles(roles: RolesStore) {

    roles.add('0', RoleName.Admin, 'Admin access on all features', new PermissionSets('system'));
    roles.add('1', RoleName.Owner, 'Has full control over the feature, including managing user access and settings.', new PermissionSets('system'));    
    roles.add('2', RoleName.Approver, 'Can review and approve/reject content submitted by others.', new PermissionSets('system'));    
    roles.add('3', RoleName.Editor, 'Can create, edit, and delete content but cannot manage users or system settings.', new PermissionSets('system'));    
    roles.add('4', RoleName.Contributor, 'Can add new content but cannot edit or delete existing content.', new PermissionSets('system'));
    roles.add('5', RoleName.Viewer, 'Can view content but cannot make any modifications.', new PermissionSets('system'));
    roles.add('6', RoleName.NoAccess, 'Block access to resource. This setting will prevail over all other permission assignments.', new PermissionSets('system'));
    roles.add('7', RoleName.Auditor, 'Permissions for external/internal such as compliance and security audits.', new PermissionSets('system'));
    roles.add('8', RoleName.Guest, 'Limited access of shared content typically used for external users.', new PermissionSets('system'));
}

export const ROLES = {
    Admin: Roles.items[0],
    Owner: Roles.items[1],
    Approver: Roles.items[2],
    Editor: Roles.items[3],
    Contributor: Roles.items[4],
    Viewer: Roles.items[5],
    NoAccess: Roles.items[6],
    Auditor: Roles.items[7],
    Guest: Roles.items[8],
}