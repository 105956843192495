import { makeAutoObservable } from "mobx";

interface Address {
    street: string;
    city: string;
    state: string;
    zip: string;
    country: string;
}

export class Location {
    location: string;
    departments: number;
    assignments: number;
    residents: number;
    address: Address;
    
    // Additional fields from OrganizationalLocation
    id?: string;
    path?: string;
    parentId?: string;
    tenantId?: string;
    
    constructor() {
        makeAutoObservable(this);
    }
}

export class Locations {
    items: Location[] = [];

    constructor() {
        loadLocations(this);
        makeAutoObservable(this);
    }
}

/**
 * This is temporary and needs to change to a real API call
 */
function loadLocations(locations: Locations) {
    locations.items = [
        {
            location: 'Main',
            departments: 10,
            assignments: 10,
            residents: 5,
            address: {
                street: '123 Main St',
                city: 'Anytown',
                state: 'CA',
                zip: '12345',
                country: 'USA'
            }
        }
    ];
}
