import FastPassRoute from "./fastpass/FastPassRoutes";
import SignInRoutes from './sign-in/SignInRoutes';
import signUpConfig from './sign-up/SignUpConfig';
import signOutConfig from './sign-out/SignOutRoutes';
import forgotPasswordConfig from './forgot-password/ForgotPasswordRoutes';
import confirmationRequiredConfig from './confirmation-required/confirmationRequiredConfig';
import resetPasswordPagesConfig from './reset-password/routes';
import ConfirmedEmailRoutes from './confirmed-email/ConfirmedEmailRoutes';
import AuthCallbackHandler from './manage-account/routes'

// import unlockSessionPagesConfig from './unlock-session/unlockSessionPagesConfig';  

const authenticationPagesConfigs = [
  FastPassRoute,
  SignInRoutes,   
  signUpConfig,
  signOutConfig,
  forgotPasswordConfig,
  confirmationRequiredConfig,
  resetPasswordPagesConfig,
  ConfirmedEmailRoutes,
  AuthCallbackHandler
];


export default authenticationPagesConfigs;
