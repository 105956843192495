import { lazy } from 'react';
import SignInPage from './SignInPage.js';
//const MicrosoftSignInPage = lazy(() => import('./MicrosoftAuth.jsx'));
// import MicrosoftSignInPage from './MicrosoftSignInPage';
//const MicrosoftSignInPage = lazy(() => import('./MicrosoftSignInPage'));

//const MicrosoftSignInPage = lazy(() => import('./MicrosoftAuthRedirect.jsx'));
//const MicrosoftSignInPage = lazy(() => import('./MicrosoftAuthPopup.jsx'));
const MicrosoftSignInPage = lazy(() => import('./MicrosoftSignInPage'));

const ConferenceSignInPage = lazy(() => import('./Conference.js'));

//Conference
import authRoles from '../../../auth/authRoles.js';
// const SignInPage = lazy(() => import('./SignInPage'));

const SignInConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'sign-in',
      children: [     
        { path: '', element: <SignInPage /> },
        { path: 'Microsoft', element: <MicrosoftSignInPage /> },
        { path: 'Conference', element: <ConferenceSignInPage /> },
      ]
    },
    {
      path: 'signin',
      children: [     
        { path: '', element: <SignInPage /> },
        { path: 'Microsoft', element: <MicrosoftSignInPage /> },
        { path: 'Conference', element: <ConferenceSignInPage /> },
      ]
    },
  ],
};

export default SignInConfig;
