/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['admin'],
  staff: ['admin', 'staff'],
  user: ['admin', 'staff', 'user', 'customer-cornerstone'],
  ahi: ['admin', 'ahi'],
  ahi_oncall: ['admin', 'ahi_oncall'],
  demo: ['admin', 'demo'],
  free: ['admin', 'free'],
  elysian: ['admin', 'elysian'], 
  sholom: ['admin', 'sholom'],
  demo2: ['admin', 'demo2'],
  thegenevasuites: ['admin', 'thegenevasuites'],
  peaceful_living: ['admin', 'peaceful_living'],
  RiverOaks: ['admin', 'RiverOaks'],
  onlyGuest: [],
};

export default authRoles;
