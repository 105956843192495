import { makeAutoObservable } from "mobx";
import { Permissions, PermissionSets } from "./Permissions.ts";
import { createViewModel, IViewModel } from 'mobx-utils';
import { Role } from "./Roles.ts";
import { Assignment, AssignmentGroup, Assignments } from "./Assignments.ts";

    //departmentName: string;
        // assignments: number  | null;
    //isSystem: boolean;
    //systemTitle?: string;   

export class JobTitle {
    name: string;    
    departmentId: string;
    locations: number | string | null;
    staff: number | string | null;
    permissions: Assignments | null;
    permissions2: number = 0;
 
    constructor() {
        makeAutoObservable(this);
    }

    addPermissions(role: Role) {

        if(this.permissions == null){
            this.permissions = new Assignments();
        }
        this.permissions.addAssignementsFromRole(role);        
    }
}

export class JobTitles {
    items: JobTitle[] = [];

    constructor() {
        loadJobTitles(this);
        makeAutoObservable(this);
    }

    add(name: string, departmentId: string, locations: number | string | null, staff: number | string | null) {

        const jobTitle = new JobTitle();
        jobTitle.name = name;
        jobTitle.departmentId = departmentId;
        jobTitle.locations = locations || 'ALL';
        jobTitle.staff = staff || 0;
        this.items.push(jobTitle);
    }
}

/**
 * This is temporary and needs to change to a real API call
 */
function loadJobTitles(jobTitles: JobTitles) {

    jobTitles.add('Executive Director / Administrator', '1', 'ALL', 0);
    jobTitles.add('Assistant Administrator', '1', 'ALL', 0);
    jobTitles.add('Business Office / Finance', '1', 'ALL', 0);
    jobTitles.add('Human Resources (HR)', '1', 'ALL', 0);
    jobTitles.add('Admissions & Marketing', '1', 'ALL', 0);
    jobTitles.add('Director of Nursing (DON)', '2', 'ALL', 0);
    jobTitles.add('Assistant Director of Nursing (ADON)', '2', 'ALL', 0);
    jobTitles.add('Registered Nurses (RNs)', '2', 'ALL', 0);
    jobTitles.add('Licensed Practical Nurses (LPNs)', '2', 'ALL', 0);
    jobTitles.add('Certified Nursing Assistants (CNAs)', '2', 'ALL', 0);
    jobTitles.add('Infection Control / Wound Care Nurses', '2', 'ALL', 0);
    jobTitles.add('Medication Aides / Technicians', '2', 'ALL', 0);
    jobTitles.add('Physical Therapy (PT)', '3', 'ALL', 0);
    jobTitles.add('Occupational Therapy (OT)', '3', 'ALL', 0);
    jobTitles.add('Speech-Language Pathology (SLP)', '3', 'ALL', 0);
    jobTitles.add('Medical Director', '4', 'ALL', 0);
    jobTitles.add('Attending Physicians', '4', 'ALL', 0);
    jobTitles.add('Nurse Practitioners (NPs) / Physician Assistants (PAs)', '4', 'ALL', 0);
    jobTitles.add('Consulting Specialists (e.g., psychiatry, podiatry)', '4', 'ALL', 0);
    jobTitles.add('Pharmacy Services', '4', 'ALL', 0);
    jobTitles.add('Social Workers', '5', 'ALL', 0);
    jobTitles.add('Case Managers', '5', 'ALL', 0);
    jobTitles.add('Resident Council / Family Liaison', '5', 'ALL', 0);
    jobTitles.add('Activities Director', '6', 'ALL', 0);
    jobTitles.add('Recreational Therapists', '6', 'ALL', 0);
    jobTitles.add('Volunteers', '6', 'ALL', 0);
    jobTitles.add('Dietary Manager', '7', 'ALL', 0);
    jobTitles.add('Registered Dietitian (RD)', '7', 'ALL', 0);
    jobTitles.add('Cooks & Kitchen Staff', '7', 'ALL', 0);
    jobTitles.add('Housekeeping / Janitorial Services', '8', 'ALL', 0);
    jobTitles.add('Laundry Services', '8', 'ALL', 0);
    jobTitles.add('Maintenance & Facilities Management', '8', 'ALL', 0);
    jobTitles.add('Security Officers', '9', 'ALL', 0);
    jobTitles.add('Emergency Preparedness & Safety Team', '9', 'ALL', 0);
    jobTitles.add('IT Support & EHR Management', '10', 'ALL', 0);
    jobTitles.add('Compliance & Risk Management', '10', 'ALL', 0);
    jobTitles.add('HIPAA & Regulatory Affairs', '10', 'ALL', 0);
    jobTitles.add('Quality Assurance & Improvement', '10', 'ALL', 0);
    jobTitles.add('Education & Training', '10', 'ALL', 0);
    jobTitles.add('Administrative Support', '10', 'ALL', 0);
}
