import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import useSettingsContext from '../../context.js';


/**
 * The Header component.
 */
function Header(props) {
	const { isLeftSidebarOpen, setIsLeftSidebarOpen, pageHeading } = useSettingsContext();
    

    const openLeftSidebar = () => {
        setIsLeftSidebarOpen(true);
    }


	return (
		<div className="flex w-full p-6 space-x-3">
			{!isLeftSidebarOpen && (
                <div style={{ color: 'rgb(149, 156, 169)'}} onClick={openLeftSidebar}>
                    <FuseSvgIcon id="OpenLeftSidebarIcon" className="mt-8 ml-3" size={15} >
                        heroicons-outline:arrow-right-end-on-rectangle
                    </FuseSvgIcon>
                </div>
			)}

			<div className="flex flex-1 flex-col">
				<Typography className="text-2xl mb-1 " style={{marginLeft: (isLeftSidebarOpen ? '3px' : '-10px'	)}}>
					{pageHeading}
				</Typography>
			</div>

			{/*isRightSidebarOpen && (
				<IconButton
					onClick={() => setIsRightSidebarOpen(!isRightSidebarOpen)}
					aria-label="toggle sidebar"
					className="border border-divider"
				>
					<FuseSvgIcon>heroicons-outline:bars-3</FuseSvgIcon>
				</IconButton>
			)*/}
		</div>
	);
}

export default Header;
