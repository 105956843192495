import React, {lazy} from 'react';
const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp'));
// const UsageReport = lazy(() => import('../modules/reports/dashboards/UsageReport/UsageReport'));

const Routes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'Demo',
      element: <Layout />,
      auth: ['admin', 'demo2'],
      children: [
        {
          comments: {/* demo */},
          path: '',
          auth: ['admin', 'demo2'],
          element: <ChatApp
            org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
            collection='demo-1-v2'
            pageTitle='Regulation and Care Facility AI'
            pageDescription="Free Regulatory AI and facility demonstration AI for Care Providers"
            chatHeader={<span>Complementary Regulations and Organization Demo</span>}
          />,
        },

        

        {
          comments: {/* demo/regs */},
          path: 'regs',
          element: <Layout />,
          auth: ['admin', 'demo2'],
          children: [
            {
              path: '',
              element: <Layout />,
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Regs'
                pageTitle='Regulations'
                pageDescription="Free Regulatory AI provided by DOVAXIS to elevant Long Term Care"
                chatHeader={<span>Complementary Regulations and Organization Demo</span>}
              />
            },

            {
              comments: {/* demo/regs/CRM */},
              path: 'CRM',          
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Regs'
                level2='CRM'
                pageTitle='Clinical Reference Material Regulations AI'
                pageDescription="Free Regulatory AI provided by DOVAXIS to elevant Long Term Care"
                chatHeader={<span>Complementary Regulations and Organization Demo</span>}
              />
            }, 
            {
              comments: {/* demo/regs/CDC */},
              path: 'CDC',          
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Regs'
                level2='CDC'
                pageTitle='CDC Regulations AI'
                pageDescription="Free Regulatory AI provided by DOVAXIS to elevant Long Term Care"
                chatHeader={<span>Complementary Regulations and Organization Demo</span>}
              />
            }, 
            {
              comments: {/* demo/regs/CFR */},
              path: 'CFR',          
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Regs'
                level2='CFR'
                pageTitle='Code of Federal Regulations (CFR) Regulations AI'
                pageDescription="Free Regulatory AI provided by DOVAXIS to elevant Long Term Care"
                chatHeader={<span>Complementary Regulations and Organization Demo</span>}
              />
            }, 
            {
              comments: {/* demo/regs/MDS */},
              path: 'MDS',          
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Regs'
                level2='MDS'
                pageTitle='MDS Regulations AI'
                pageDescription="Free Regulatory AI provided by DOVAXIS to elevant Long Term Care"
                chatHeader={<span>Complementary Regulations and Organization Demo</span>}
              />
            }, 

            {
              comments: {/* demo/regs/snf */},
              path: 'SNF',
              auth: ['admin', 'demo2'],
              children: [
                {
                  path: '',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                    org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    level1='Regs'
                    level2='SNF'
                    pageTitle='Regulations'
                    pageDescription="Free Regulatory AI provided by DOVAXIS to elevant Long Term Care"
                    chatHeader={<span>Complementary Regulations and Organization Demo</span>}
                  />
                },
                {
                  comments: {/* demo/regs/snf/az */},
                  path: 'AZ',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                  org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    keywords="snf"
                    level1='Regs'
                    level2='SNF'
                    level3='AZ'
                    pageTitle="Arizona State Regulations for SNFs"
                    pageDescription="Free SNF AI for Care Specialists"
                    chatHeader={<span>Skilled Nursing</span>}
                  />
                },
                {
                  path: 'CO',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                  org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    keywords="snf"
                    level1='Regs'
                    level2='SNF'
                    level3='CO'
                    pageTitle="Colorado State Regulations for SNFs"
                    pageDescription="Free SNF AI for Care Specialists"
                    chatHeader={<span>Skilled Nursing</span>}
                  />,
                },
                {
                  path: 'MN',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                  org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    keywords="snf"
                    level1='Regs'
                    level2='SNF'
                    level3='MN'
                    pageTitle="Minnesota State Regulations for SNFs"
                    pageDescription="Free SNF AI for Care Specialists"
                    chatHeader={<span>Skilled Nursing</span>}
                  />,
                },
                {
                  path: 'TN',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                  org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    keywords="snf"
                    level1='Regs'
                    level2='SNF'
                    level3='TN'
                    pageTitle="Tennessee State Regulations for SNFs"
                    pageDescription="Free SNF AI for Care Specialists"
                    chatHeader={<span>Skilled Nursing</span>}
                  />,
                },
                {
                  path: 'TN',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                  org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    keywords="snf"
                    level1='Regs'
                    level2='SNF'
                    level3='TN'
                    pageTitle="Tennessee State Regulations for SNFs"
                    pageDescription="Free SNF AI for Care Specialists"
                    chatHeader={<span>Skilled Nursing</span>}
                  />,
                },
              ]
            },
            
            {
              path: 'AL',
              auth: ['admin', 'demo2'],
              children: [
                {
                  path: '',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                    org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    level1='Regs'
                    level2='AL'
                    keywords="al"
                    pageTitle="State Regulations for Assisted Living Facilities"
                    pageDescription="Free AL AI for Care Specialists"
                    chatHeader={<span>Assisted Living</span>}
                  />
                },
                {
                  path: 'AZ',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                    org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    level1='Regs'
                    level2='AL'
                    level3='AZ'
                    pageTitle="Arizona Regulations for Assisted Living Facilities"
                    pageDescription="Free AL AI for Care Specialists"
                    chatHeader={<span>Assisted Living</span>}
                  />
                },
                {
                  path: 'CO',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                    org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    level1='Regs'
                    level2='AL'
                    level3='CO'
                    pageTitle="Colorado Regulations for Assisted Living Facilities"
                    pageDescription="Free AL AI for Care Specialists"
                    chatHeader={<span>Assisted Living</span>}
                  />
                },
                {
                  path: 'FL',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                    org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    level1='Regs'
                    level2='AL'
                    level3='FL'
                    pageTitle="Florida Regulations for Assisted Living Facilities"
                    pageDescription="Free AL AI for Care Specialists"
                    chatHeader={<span>Assisted Living</span>}
                  />
                },
                {
                  path: 'MN',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                    org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    level1='Regs'
                    level2='AL'
                    level3='MN'
                    pageTitle="Minnesota Regulations for Assisted Living Facilities"
                    pageDescription="Free AL AI for Care Specialists"
                    chatHeader={<span>Assisted Living</span>}
                  />
                },
                {
                  path: 'TN',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                    org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    level1='Regs'
                    level2='AL'
                    level3='TN'
                    pageTitle="Tennessee Regulations for Assisted Living Facilities"
                    pageDescription="Free AL AI for Care Specialists"
                    chatHeader={<span>Assisted Living</span>}
                  />
                },
                {
                  path: 'WI',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                    org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                    collection='demo-1-v2'
                    level1='Regs'
                    level2='AL'
                    level3='WI'
                    pageTitle="Wisconsin Regulations for Assisted Living Facilities"
                    pageDescription="Free AL AI for Care Specialists"
                    chatHeader={<span>Assisted Living</span>}
                  />
                }
              ]
            }
          ],
        },

        {
          path: 'BrightSide',
          element: <Layout />,
          auth: ['admin', 'demo2'],
          children: [
            {
              path: '',
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Facility'
                pageTitle='Facility Documents - BrightSide Care Center'
                pageDescription='Sample AI at fictitious care facility'
                chatHeader={<span>This could be your facility's document experience.</span>}
              />,
            },
            {
              path: 'Clinical',
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Facility'
                level2='Clinical Skills & Competencies'
                pageTitle="Clinical Skills & Competencies - BrightSide Care Center"
                pageDescription="BrightSource example at fictitious care facility"
                chatHeader={<span>Instant access to your resources through conversations.</span>}
              />,
            },
            {
              path: 'Culinary',
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Facility'
                level2='Culinary Services'
                pageTitle="Culinary Services - BrightSide Care Center"
                pageDescription="BrightSource example at fictitious care facility"
                chatHeader={<span>Instant access to your resources through conversations.</span>}
              />,
            },
    
            {
              path: 'Emergency',
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Facility'
                level2='Emergency Preparation'
                pageTitle="Emergency Preparation - BrightSide Care Center"
                pageDescription="BrightSource example at fictitious care facility"
                chatHeader={<span>Empower your staff with AI.</span>}
              />,
            },
    
            {
              path: 'HR',
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Facility'
                level2='HR & Employment'
                pageTitle="HR & Employment - BrightSide Care Center"
                pageDescription="BrightSource example at fictitious care facility."
                chatHeader={<span>Get accurate up-to-date information as easily as chatting with a colleague.</span>}
              />,
            },
    
            {
              path: 'Tools',
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Facility'
                level2='Infection Control Toolkit'
                pageTitle="Infection Control Toolkit - BrightSide Care Center"
                pageDescription="BrightSource example at fictitious care facility"
                chatHeader={<span>BrightSource informs and reports on change, across the organization, the industry, and beyond.</span>}
              />,
            },
    
            {
              path: 'ProductDocs',
              auth: ['admin', 'demo2'],
              element: <ChatApp
                org={{key: "demo", name: "BrightSource Demo", id: "4GZiP4Dlr3odHrsb2Uqp"}}
                collection='demo-1-v2'
                level1='Facility'
                level2='Manufacturer’s Instructions'
                pageTitle="Manufacturer’s Instructions - BrightSide Care Center"
                pageDescription="BrightSource example at fictitious care facility"
                chatHeader={<span>BrightSource informs and reports on change, across the organization, the industry, and beyond.</span>}
              />,
            },
    
          ],
        },
      ]
    },
    
    
  ],
};

export default Routes;
