import React, {lazy} from 'react';
import SettingsApp from './app.js';
const SettingsDashboard = lazy(() => import('./ui/layout/Dashboard/Page.tsx'));

// Permissions
const PermissionsDashboard = lazy(() => import('./ui/permissions/dashboard/Page.tsx'));
const GroupsPage = lazy(() => import('./ui/permissions/groups/Page.tsx'));
const RolesPage = lazy(() => import('./ui/permissions/roles/Page.tsx'));
const JobTitlesPage = lazy(() => import('./ui/permissions/job_titles/Page.tsx'));
const UsersPage = lazy(() => import('./ui/permissions/users/Page.tsx'));
const DocTypesPage = lazy(() => import('./ui/permissions/docTypes/Page.tsx'));

// Facility
const FacilityDashboard = lazy(() => import('./ui/facility/dashboard/Page.tsx'));
const LocationsPage = lazy(() => import('./ui/facility/locations/Page.tsx'));
const DepartmentsPage = lazy(() => import('./ui/facility/departments/Page.tsx'));
const ResidentsPage = lazy(() => import('./ui/facility/residents/Page.tsx'));

const Routes = {
    settings: {
      layout: {
        config: {},
      },
    },
    routes: [
      {
        path: 'Settings',
        element: <SettingsApp />,
        children: [     
          { path: '', element: <SettingsDashboard /> },
          { path: 'Permissions',
            children: [     
              { path: '', element: <PermissionsDashboard /> },
              { path: 'Groups', element: <GroupsPage /> },              
              { path: 'JobTitles', element: <JobTitlesPage /> },
              { path: 'Users', element: <UsersPage /> },
              { path: 'DocTypes', element: <DocTypesPage /> },
              { path: 'Roles', element: <RolesPage /> },
            ],
          },
          { path: 'Facility',
            children: [     
              { path: '', element: <FacilityDashboard /> },
              { path: 'Locations', element: <LocationsPage /> },
              { path: 'Departments', element: <DepartmentsPage /> },
              { path: 'Residents', element: <ResidentsPage /> },
            ],
          }
        ]
      }
    ],
  };
  

  export default Routes;