import { makeAutoObservable } from "mobx";
import { v4 as uuid } from 'uuid';
import { PermissionSets, PermissionSet, Permission } from "./PermissionSets.ts";
import { Role } from "./Roles";


export class Assignment {
    id: string;
    permissionId: string;
    name: string;
    command: string;
    operation: string;
    description: string;
    value: boolean = false;
    createdOn: string;
    createdBy: string;
    modifiedOn: string;
    modifiedBy: string;
    
    constructor() {                
        makeAutoObservable(this);
    }
}

export class AssignmentGroup {
    id: string;
    items: Assignment[] = [];
    roleName: string;    
    name: string;
    description: string;
    createdOn: string;
    createdBy: string;
    modifiedOn: string;
    modifiedBy: string;

    constructor() {
        makeAutoObservable(this);
    }

    addPermissionsFromRole(permissionSet: PermissionSet){

        permissionSet.items.forEach((permission: Permission) => {
            const assignment = new Assignment();
            assignment.id = uuid();
            assignment.permissionId = permission.id;
            assignment.name = permission.name;
            assignment.command = permission.command;
            assignment.operation = permission.operation;
            assignment.description = permission.description;
            assignment.value = permission.value;
            //assignment.createdOn = permission.createdOn;
            //assignment.createdBy = permission.createdBy;
            //assignment.modifiedOn = permission.modifiedOn;
            //assignment.modifiedBy = permission.modifiedBy;
            this.items.push(assignment);
        });
    }
}

export class Assignments {
    id: string;
    items: AssignmentGroup[] = [];    
    description: string;
    createdOn: string;
    createdBy: string;
    modifiedOn: string;
    modifiedBy: string;

    constructor() {
        makeAutoObservable(this);
    }

    addAssignementsFromRole(role: Role) {

        role.permissions.items.forEach((permissionSet: PermissionSet) => {
            
            const assignmentGroup = new AssignmentGroup();
            assignmentGroup.id = uuid();
            assignmentGroup.roleName = role.name;
            assignmentGroup.name = permissionSet.name;
            assignmentGroup.description = permissionSet.description;
            assignmentGroup.addPermissionsFromRole(permissionSet);
            //assignment.createdOn = permission.createdOn;
            //assignment.createdBy = permission.createdBy;
            //assignment.modifiedOn = permission.modifiedOn;
            //assignment.modifiedBy = permission.modifiedBy;
            this.items.push(assignmentGroup);
        });
    }
}