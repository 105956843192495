import { makeAutoObservable, trace, autorun, getDependencyTree   } from "mobx";
import { DataSensitivity } from "src/security/model/DataSensitivity.ts";
import { v4 as uuidv4 } from 'uuid';

export class DocumentType {

    // id: string;
    type: string;
    sensitivity: DataSensitivity;
    isSystemType: boolean;
    tabName: string;
    isEditable: boolean;
    
    constructor() {
        makeAutoObservable(this);
    }
}

export class DocumentTypes {

    items: DocumentType[] = [];

    constructor() {

        loadTypes(this);
        makeAutoObservable(this);
    }

}

/**
 * This is temporary and needs to change to a real API call
  */
function loadTypes(documentTypes: DocumentTypes) {

    documentTypes.items = [
        { type: 'General', sensitivity: DataSensitivity.LOW, isSystemType: true, tabName: 'DOC', isEditable: false},
        { type: 'Policies and Procedures', sensitivity: DataSensitivity.MODERATE, isSystemType: true, tabName: 'POLICY', isEditable: false},
        { type: 'Financial', sensitivity: DataSensitivity.RESTRICTED, isSystemType: true, tabName: 'FINANCIAL', isEditable: false },
        { type: 'Regulations', sensitivity: DataSensitivity.UNRESTRICTED, isSystemType: true, tabName: 'REGULATION', isEditable: false},
        { type: 'Training', sensitivity: DataSensitivity.LOW, isSystemType: true, tabName: 'TRAINING', isEditable: false},
        { type: 'Patient Records', sensitivity: DataSensitivity.VERY_RESTRICTED, isSystemType: true, tabName: 'PATIENT', isEditable: false}
    ];
}

