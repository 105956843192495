import FuseNavigation from '@fuse/core/FuseNavigation';
import { FuseNavItemType } from '@fuse/core/FuseNavigation/types/FuseNavItemType';
import Typography from '@mui/material/Typography';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import useSettingsContext from '../../context.js';
import './LeftSidebar.css';
// import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
// import MedicalInformationIcon from '@mui/icons-material/MedicalInformationOutlined';
// import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
// import BadgeIcon from '@mui/icons-material/BadgeOutlined';

/**
 * Navigation data
 */
const navigationData: FuseNavItemType[] = [
	{
		id: 'permissions',
		title: 'Access Control',
		subtitle: 'Configure privileges',
		type: 'group',
		children: [
			{
				id: 'permissions-staff',
				title: 'Users',
				type: 'item',
				icon: 'heroicons-outline:user',
				url: 'Permissions/Users',
			},
			
			{
				id: 'permissions-groups',
				title: 'Groups',
				type: 'item',
				icon: 'heroicons-outline:users',
				url: 'Permissions/Groups',
			},
			{
				id: 'permissions-titles',
				title: 'Job Titles',
				type: 'item',
				icon: 'heroicons-outline:user-circle',
				url: 'Permissions/JobTitles',
			},
			{
				id: 'permissions-docTypes',
				title: 'Document Types',
				type: 'item',
				icon: 'heroicons-outline:document-duplicate',
				url: 'Permissions/DocTypes',
			},
			{
				comments: 'icon was: user-group shield-check',
				id: 'permissions-roles',
				title: 'Roles',
				type: 'item',
				icon: 'material-outline:badge',
				url: 'Permissions/Roles',
			}
		]
	},
	{
		id: 'divider1',
		type: 'divider'
	},
	{
		id: 'facility',
		title: 'Facility',
		subtitle: 'Location settings',
		type: 'group',
		url: 'Facility',
		children: [
			{
				id: 'facility-locations',
				title: 'Locations',
				type: 'item',
				icon: 'heroicons-outline:office-building',
				url: 'Facility/Locations',
				/*,
				badge: {
					title: '49',
					classes: 'px-2 bg-primary text-on-primary rounded-full'
				}
				*/
			},
			{
				id: 'facility-departments',
				title: 'Departments',
				type: 'item',
				icon: 'heroicons-outline:archive',
				url: 'Facility/Departments',		
			},
			{
				id: 'facility-residents',
				title: 'Residents',
				type: 'item',
				icon: 'heroicons-outline:clipboard-check',
				url: 'Facility/Residents',		
			}	
		]
	},
	{
		id: 'divider2',
		type: 'divider'
	},
	{
		id: 'reports',
		title: 'Reports',
		type: 'group',
		children: [
			{
				id: 'reports-usage',
				title: 'Usage',
				type: 'item',
				icon: 'heroicons-outline:presentation-chart-line'				
			},
		]
	},
	{
		id: 'divider3',
		type: 'divider'
	},
	{
		id: 'notifications',
		title: 'System',
		type: 'group',
		children: [
			{
				id: 'system-notifications',
				title: 'Notifications',
				type: 'item',
				icon: 'heroicons-outline:bell'				
			},
			{
				id: 'system-ai',
				title: 'AI Settings',
				type: 'item',
				icon: 'heroicons-outline:sparkles'				
			},
		]
	},
];

/**
 * The LeftSidebar component.
 */
function LeftSidebar() {

	const { setIsLeftSidebarOpen } = useSettingsContext();

	const closeSidebar = () => {
		console.log('closeSidebar');
		setIsLeftSidebarOpen(false);
	}

	return (
		<div className=" min-h-6xl">
			<div className="flex justify-end p-4" onClick={closeSidebar} >
				<FuseSvgIcon id="CloseLeftSidebarIcon" className="mt-6" size={15} >
					heroicons-outline:arrow-left-start-on-rectangle
				</FuseSvgIcon>				
			</div>
			<FuseNavigation id="SettingsLeftSidebar" className="pt-0" navigation={navigationData} dense={true} />
		</div>
	);
}

export default LeftSidebar;
