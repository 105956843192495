/**
 * Data Sensitivity Levels
 * 
 * Based on HL7 FHIR v4 Confidentiality codes (http://terminology.hl7.org/CodeSystem/v3-Confidentiality)
 * 
 * - **U (Unrestricted)** - Information not classified as sensitive (e.g., publicly available information)
 * - **L (Low)** - De-identified or minimally sensitive information with low risk
 * - **M (Moderate)** - Moderately sensitive information with moderate risk if disclosed
 * - **N (Normal)** - Typical, non-stigmatizing health information (e.g., standard PHI under HIPAA)
 * - **R (Restricted)** - Highly sensitive, potentially stigmatizing information (e.g., mental health, HIV status)
 * - **V (Very Restricted)** - Extremely sensitive information requiring highest confidentiality
 */

export enum DataSensitivity {
    UNRESTRICTED = "U",           // Not classified as sensitive
    LOW = "L",                    // De-identified/minimal sensitivity
    MODERATE = "M",               // Moderately sensitive
    NORMAL = "N",                 // Standard PHI
    RESTRICTED = "R",             // Highly sensitive
    VERY_RESTRICTED = "V"         // Extremely sensitive
}

export interface IDataSensitivity {    
    title: string;
    code: string;    
    definition: string;
    examples: string;
    usage: string;
    level: number;
    map:  string;
    color: string;
}

export let DataSensitivityInfo = new Map<DataSensitivity, IDataSensitivity>([
    [DataSensitivity.UNRESTRICTED, {
        title: "Unrestricted",
        code: DataSensitivity.UNRESTRICTED,
        definition: "Privacy metadata indicating that the information is not classified as sensitive",
        examples: "Includes publicly available information, e.g., business name, phone, email or physical address",
        usage: "This metadata indicates that the receiver has no obligation to consider additional policies when making access control decisions. Note that in some jurisdictions, personally identifiable information must be protected as confidential, so it would not be appropriate to assign a confidentiality code of \"unrestricted\" to that information even if it is publicly available",
        level: 0,
        map: "",
        color: 'bg-green-500 text-white'
    }],

    [DataSensitivity.LOW, {
        title: "Low",
        code: DataSensitivity.LOW,
        definition: "Privacy metadata indicating that the information has been de-identified, and there are mitigating circumstances that prevent re-identification, which minimize risk of harm from unauthorized disclosure",
        examples: "Includes anonymized, pseudonymized, or non-personally identifiable information such as HIPAA limited data sets",
        usage: "This metadata indicates the receiver may have an obligation to comply with a data use agreement",
        map: "No clear map to ISO 13606-4 Sensitivity Level (1) Care Management: RECORD_COMPONENTs that might need to be accessed by a wide range of administrative staff to manage the subject of care's access to health services",
        level: 1,
        color: 'bg-green-700 text-white'
    }],

    [DataSensitivity.MODERATE, {
        title: "Moderate",
        code: DataSensitivity.MODERATE,
        definition: "Privacy metadata indicating moderately sensitive information, which presents moderate risk of harm if disclosed without authorization",
        examples: "Includes allergies of non-sensitive nature used inform food service; health information a patient authorizes to be used for marketing, released to a bank for a health credit card or savings account; or information in personal health record systems that are not governed under health privacy laws",
        usage: "This metadata indicates that the receiver may be obligated to comply with the receiver's terms of use or privacy policies",
        map: "Partial Map to ISO 13606-4 Sensitivity Level (2) Clinical Management: Less sensitive RECORD_COMPONENTs that might need to be accessed by a wider range of personnel not all of whom are actively caring for the patient (e.g. radiology staff)",
        level: 2,
        color: 'bg-teal-500 text-white'
    }],
    [DataSensitivity.NORMAL, {
        title: "Normal",
        code: DataSensitivity.NORMAL,
        definition: "Privacy metadata indicating that the information is typical, non-stigmatizing health information, which presents typical risk of harm if disclosed without authorization",
        examples: "In the US, this includes what HIPAA identifies as the minimum necessary protected health information (PHI) given a covered purpose of use (treatment, payment, or operations). Includes typical, non-stigmatizing health information disclosed in an application for health, workers compensation, disability, or life insurance",
        usage: "This metadata indicates that the receiver may be obligated to comply with applicable jurisdictional privacy law or disclosure authorization",
        map: "Partial Map to ISO 13606-4 Sensitivity Level (3) Clinical Care: Default for normal clinical care access (i.e. most clinical staff directly caring for the patient should be able to access nearly all of the EHR)",
        level: 3,
        color: 'bg-teal-700 text-white'
    }],
    [DataSensitivity.RESTRICTED, {
        title: "Restricted",
        code: DataSensitivity.RESTRICTED,
        definition: "Privacy metadata indicating highly sensitive, potentially stigmatizing information, which presents a high risk to the information subject if disclosed without authorization",
        examples: "Includes information that is additionally protected such as sensitive conditions mental health, HIV, substance abuse, domestic violence, child abuse, genetic disease, and reproductive health; or sensitive demographic information such as a patient's standing as an employee or a celebrity",
        usage: "This metadata indicates that the receiver may be obligated to comply with applicable, prevailing (default) jurisdictional privacy law or disclosure authorization",
        map: "Partial Map to ISO 13606-4 Sensitivity Level (3) Clinical Care: Default for normal clinical care access",
        level: 4,
        color: 'bg-red-500 text-white'
    }],

    [DataSensitivity.VERY_RESTRICTED, {
        title: "Very Restricted",
        code: DataSensitivity.VERY_RESTRICTED,
        definition: "Privacy metadata indicating that the information is extremely sensitive and likely stigmatizing health information that presents a very high risk if disclosed without authorization",
        examples: "Includes information about a victim of abuse, patient requested information sensitivity, and taboo subjects relating to health status that must be discussed with the patient by an attending provider before sharing with the patient. May also include information held under legal lock or attorney-client privilege",
        usage: "This metadata indicates that the receiver may not disclose this information except as directed by the information custodian, who may be the information subject",
        map: "",
        level: 5,
        color: 'bg-red-700 text-white'
    }]
]);

/*
DataSensitivityInfo.getByTitle = function(title: string){ 

    for (const [key, value] of this.entries()) {
        if (value?.title === title) {
            return value;
        }
      }
     
    return null;
}
*/

