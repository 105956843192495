import SettingsProvider from './provider.js';
import SettingsLayout from './ui/layout/layout.tsx';


import '../../../styles/syncfusion/dovaxis/light/material.css';
import './app.css';

const SettingsApp = () => {
  return (
    <SettingsProvider>
      <SettingsLayout />
    </SettingsProvider>
  );
}

export default SettingsApp;