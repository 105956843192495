import { firestore } from 'src/app/auth/Firebase';
import { collection, doc, writeBatch, getDocs, getFirestore, setDoc, getDoc } from 'firebase/firestore';
import { PermissionSettings, PermissionSetting } from '../store/PermissionSettings.ts';

const CONTAINER_PERMISSION_SETTINGS = 'Permission Settings';



/*
// Delete existing documents in the collection
        const existingDocs = await getDocs(permissionsCollectionRef);
        existingDocs.forEach((doc) => {
            batch.delete(doc.ref);
        });
        */

export async function getPermissionSettings(orgKey: string): Promise<PermissionSettings> {
    let data = await getDoc(doc(firestore, CONTAINER_PERMISSION_SETTINGS, orgKey));
    return data.data() as PermissionSettings;
}

export async function savePermissionSettings(permissionSettings: PermissionSettings) {

    let data = {
        orgId: 'system',
        orgKey: 'system',
        createdOn: new Date().toISOString(),
        createdBy: 'system',
        modifiedOn: new Date().toISOString(),
        modifiedBy: 'system'
    }

    let permissionsSets = [...new Set(permissionSettings.items.map((setting: PermissionSetting) => setting.permissionSetName))];
    permissionsSets.forEach((permissionSetName: string) => {
        let match = permissionSettings.items.filter((setting: PermissionSetting) => setting.permissionSetName === permissionSetName);
        data[permissionSetName] = {};
        match.forEach((setting: PermissionSetting) => {
            data[permissionSetName][setting.permissionName] = {
                id: setting.id,
                command: setting.command,
                operation: setting.operation,
                description: setting.description,                
                roles: {
                    admin: copyRoleSettings(setting.admin),
                    owner: copyRoleSettings(setting.owner),
                    approver: copyRoleSettings(setting.approver),
                    editor: copyRoleSettings(setting.editor),
                    contributor: copyRoleSettings(setting.contributor),
                    viewer: copyRoleSettings(setting.viewer),
                    auditor: copyRoleSettings(setting.auditor),
                    guest: copyRoleSettings(setting.guest),
                }
            };
        });        
    });


    try {
        await setDoc(doc(firestore, CONTAINER_PERMISSION_SETTINGS, data.orgKey), data);
        return data;
    }
    catch(ex){
        console.error('Error saving permission settings:', ex);
        throw ex;
    }

}


export async function savePermissionSettingsXXX(permissionSettings: PermissionSettings) {

    

    try {

        const batch = writeBatch(firestore);

        // Create a collection reference for permission settings
        const permissionsCollectionRef = collection(firestore, CONTAINER_PERMISSION_SETTINGS);
        const docRef = doc(permissionsCollectionRef);
        let setting = permissionSettings.items[0];

        batch.set(docRef, {
            id: setting.id,
            permissionSetName: setting.permissionSetName,
            permissionName: setting.permissionName,
            command: setting.command,
            operation: setting.operation,
            description: setting.description,
            roles: {
                admin: copyRoleSettings(setting.admin),
                owner: copyRoleSettings(setting.owner),
                approver: copyRoleSettings(setting.approver),
                editor: copyRoleSettings(setting.editor),
                contributor: copyRoleSettings(setting.contributor),
                viewer: copyRoleSettings(setting.viewer),
                auditor: copyRoleSettings(setting.auditor),
                guest: copyRoleSettings(setting.guest),
                noAccess: copyRoleSettings(setting.noAccess)
            }
        });
        
    
        // Commit the batch
        await batch.commit();
        console.log('Permission settings saved successfully');
        
    } catch (error) {
        console.error('Error saving permission settings:', error);
        throw error;
    }

    

}

function copyRoleSettings(roleSetting: PermissionSetting){

    let info = {
        id: roleSetting.id,
        createdOn: roleSetting.createdOn,
        createdBy: roleSetting.createdBy,
        modifiedOn: roleSetting.modifiedOn,
        modifiedBy: roleSetting.modifiedBy,
        value: roleSetting.value
    }    
    return info;
}




export async function savePermissionSettingsOld(permissionSettings: PermissionSettings) {
    try {

        debugger;

        const batch = writeBatch(firestore);

        // Create a collection reference for permission settings
        
        const permissionsCollectionRef = collection(firestore, CONTAINER_PERMISSION_SETTINGS);
        

        // Add new documents
        permissionSettings.items.forEach((setting: PermissionSetting) => {
            const docRef = doc(permissionsCollectionRef);
            batch.set(docRef, {
                id: setting.id,
                permissionSetName: setting.permissionSetName,
                permissionName: setting.permissionName,
                command: setting.command,
                operation: setting.operation,
                description: setting.description,
                roles: {
                    admin: copyRoleSettings(setting.admin),
                    owner: copyRoleSettings(setting.owner),
                    approver: copyRoleSettings(setting.approver),
                    editor: copyRoleSettings(setting.editor),
                    contributor: copyRoleSettings(setting.contributor),
                    viewer: copyRoleSettings(setting.viewer),
                    auditor: copyRoleSettings(setting.auditor),
                    guest: copyRoleSettings(setting.guest),
                    noAccess: copyRoleSettings(setting.noAccess)
                }
            });
        });

        // Commit the batch
        await batch.commit();
        console.log('Permission settings saved successfully');
        
    } catch (error) {
        console.error('Error saving permission settings:', error);
        throw error;
    }
}