import { makeAutoObservable } from "mobx";

// UPDATE TO FHIR Standard: https://hl7.org/fhir/R4/patient.html

interface Address {
    street: string;
    city: string;
    region: string;
    postalCode: string;
    country: string;
}

export class Resident {
    id: string;
    firstName: string;
    lastName: string;
    title: string;
    titleOfCourtesy?: string;
    birthDate?: Date;
    hireDate?: Date;
    address?: Address;
    homePhone?: string;
    extension?: string;
    notes?: string;
    reportsTo?: number;
    departments?: string[];
    locations?: string[];
    isActive: boolean;
    
    constructor() {
        makeAutoObservable(this);
    }
}

export class Residents {
    items: Residents[] = [];

    constructor() {
        loadResidents(this);
        makeAutoObservable(this);
    }
}

/**
 * This is temporary and needs to change to a real API call
 */
function loadResidents(residents: Residents) {
    residents.items = [
        {
            id: '1',
            firstName: 'John',
            lastName: 'Doe',
            title: 'Care and Wellness Director',
            isActive: true,
            departments: ['Care and Wellness'],
            locations: ['Main'],
            address: {
                street: '123 Main St',
                city: 'Anytown',
                region: 'CA',
                postalCode: '12345',
                country: 'USA'
            }
        }
    ];
}
