import FuseUtils from '@fuse/utils';
import { Navigate } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import settingsConfig from 'app/configs/settingsConfig';
import AuthenticationRoutes from '../main/authentication/authenticationRoutes';
import Error404Page from '../main/404/Error404Page';
// import ReferralRoutes from '../modules/referrals/routes';
import IntegrationRoutes from '../modules/integration/routes';
import SupportRoutes from '../modules/support/routes';
import DataModelRoutes from '../modules/integration/DataModels/routes';
import SystemTestsRoutes from '../modules/system/test/routes';
import PrompRoutes from '../modules/prompts/routes';
import AutomationRoutes from '../modules/automation/routes';
import LumberjackRoutes from '../modules/lumberjack/routes';
import RegulationChat from '../modules/chat_regulation/routes';
// import PolicyChat from '../modules/chat_policy/routes';
import ClientOldRoutes from '../modules/clients/routes';
import IamRoutes from '../modules/iam/routes';
import ClientRoutes from '../clients';
import RegistrationRoutes from '../registration/RegistrationRoutes';
import PresentationRoutes from '../modules/demo/routes';
import ReportRoutes from '../modules/reports/routes';
import SurveyRoutes from '../modules/ChatApp/Survey/Routes';
import SettingsRoutes from '../modules/settings/routes';

const routeConfigs = [
  ...AuthenticationRoutes,
  PresentationRoutes,
  RegistrationRoutes,
  ClientRoutes,
  IntegrationRoutes, 
  SupportRoutes, 
  DataModelRoutes, 
  SystemTestsRoutes,
  PrompRoutes,
  AutomationRoutes,
  LumberjackRoutes,
  RegulationChat,
  ClientOldRoutes,
  IamRoutes,
  ReportRoutes,
  SurveyRoutes,
  SettingsRoutes
];

// auth: settingsConfig.defaultAuth,

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    auth: ['root access blocked'],    
    element: <FuseLoading />,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '404',
    element: <Error404Page />,
  }
  ,{
    path: '*',
    element: <Navigate to="404" />,
  }
];
/*

{
    path: '*',
    element: <Navigate to="404" />,
  },
  */

export default routes;
