import { makeAutoObservable, trace, autorun, getDependencyTree   } from "mobx";
import { DocumentTypes } from "./DocumentTypes.ts";
import { JobTitles } from "./JobTitles.ts";
import { Departments } from "./Departments.ts";
import { Locations } from "./Locations.ts"; 
import { Residents } from "./Residents.ts";
import { Users } from "./Users.ts";
import { Roles } from "./Roles.ts";
import { PermissionSets } from "./PermissionSets.ts";
import PermissionSettings from './PermissionSettings.ts';

export class Settings {

    documentTypes: DocumentTypes = new DocumentTypes();
    jobTitles: JobTitles = new JobTitles();
    departments: Departments = new Departments();
    locations: Locations = new Locations();
    residents: Residents = new Residents();
    users: Users = new Users();
    permissionSets = new PermissionSets();
    roles = Roles;   // Note: roles is uniquie
    permissionSettings: PermissionSettings = new PermissionSettings(this); // must come after Roles and PermissionSets

    constructor() {
        makeAutoObservable(this);
    }
}


export default Settings;