// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import './i18n';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import { registerLicense } from '@syncfusion/ej2-base';

import { config } from "dotenv";
config();

//console.log(`SYNC: ${process.env.REACT_APP_FIREBASE_SYNCFUSION_KEY}`);
// eventally load settings through secure DOVAXIS APIs: https://stackoverflow.com/questions/34442739/how-does-one-set-private-environment-variables-on-firebase-hosting
//console.log(`SYNC ENV: ${process.env.REACT_APP_FIREBASE_SYNCFUSION_KEY}`);
//console.log(`SYNC KEY: ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sd0JiWntWc3VXR2NV`);

// TODO: Setup enterprise key for prod and "ORg..." key for dev

if (window.location.hostname === 'localhost') {
    console.log('running on local')
  registerLicense("ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sd0JiWn1WdXJcR2Bf");
} else {
  registerLicense("Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWX5ccnVQRWZYVUZzX0A=");
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
