import React, {lazy} from 'react';
const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp'));
const SearchHistoryReport = lazy(() => import('../modules/reports/SearchHistory/Report'));
const SearchHistoryHomePage = lazy(() => import('../modules/reports/SearchHistory/pages/HomePage'));


const Routes = {
    settings: {
      layout: {
        config: {},
      },
    },
    routes: [
      {
        path: 'RiverOaks',
        element: <Layout />,
        auth: ['admin', 'RiverOaks'],
        children: [
          {
            path: '',
            auth: ['admin', 'RiverOaks'],
            element: <ChatApp
              org={{id: "c4ef57cc-2d1e-4685-94ac-cdf67d3fa94a", key: "riveroaksmn", name: "River Oaks of Minnesota"}}
              collection="RiverOaks-c1-v1"
              pageTitle="River Oaks Resources"
              pageDescription="BrightSource AI Assistant"
              chatHeader={<span>River Oaks BrightSources</span>}
            />,
          },
          {
            path: 'Regulations',
            auth: ['admin', 'RiverOaks'],
            children: [
                {
                    path: '',
                    auth: ['admin', 'RiverOaks'],
                    element: <ChatApp
                    org={{id: "c4ef57cc-2d1e-4685-94ac-cdf67d3fa94a", key: "riveroaksmn", name: "River Oaks of Minnesota"}}
                    collection="RiverOaks-c1-v1"
                    level1="Regulations"
                    pageTitle="River Oaks Resources"
                    pageDescription="BrightSource AI Assistant"
                    chatHeader={<span>River Oaks BrightSources</span>}
                    />,
                },
                {
                    path: 'Federal',
                    auth: ['admin', 'RiverOaks'],
                    element: <ChatApp
                        org={{id: "c4ef57cc-2d1e-4685-94ac-cdf67d3fa94a", key: "riveroaksmn", name: "River Oaks of Minnesota"}}
                        collection="RiverOaks-c1-v1"
                        level1="Regulations"
                        level2="Federal"
                        pageTitle="Federal Rules and Regulations"
                        pageDescription="BrightSource AI Assistant"
                        chatHeader={<span>Federal Rules and Regulations</span>}
                    />,
                  },
                  {
                    path: 'MN',
                    auth: ['admin', 'RiverOaks'],
                    element: <ChatApp
                        org={{id: "c4ef57cc-2d1e-4685-94ac-cdf67d3fa94a", key: "riveroaksmn", name: "River Oaks of Minnesota"}}
                        collection="RiverOaks-c1-v1"
                        level1="Regulations"
                        level2="MN"
                        pageTitle="River Oaks Minnesota Resources"
                        pageDescription="BrightSource AI Assistant"
                        chatHeader={<span>MN Resources</span>}
                    />,
                  },
                  {
                    path: 'WI',
                    auth: ['admin', 'RiverOaks'],
                    element: <ChatApp
                        org={{id: "c4ef57cc-2d1e-4685-94ac-cdf67d3fa94a", key: "riveroaksmn", name: "River Oaks of Minnesota"}}
                        collection="RiverOaks-c1-v1"
                        level1="Regulations"
                        level2="MN"
                        pageTitle="River Oaks Wisconsin Resources"
                        pageDescription="BrightSource AI Assistant"
                        chatHeader={<span>Wisconsin Resources</span>}
                    />,
                  },         
            ]
          },
          
          {
            path: 'HRAndBenefits',
            auth: ['admin', 'RiverOaks'],
            element: <ChatApp
                org={{id: "c4ef57cc-2d1e-4685-94ac-cdf67d3fa94a", key: "riveroaksmn", name: "River Oaks of Minnesota"}}
                collection="RiverOaks-c1-v1"
                level1="HR & Benefits"
                pageTitle="River Oaks HR Resources"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>HR & Benefits</span>}
                />,
          },
          {
            path: 'PoliciesAndProcedures',
            auth: ['admin', 'RiverOaks'],
            element: <ChatApp
                org={{id: "c4ef57cc-2d1e-4685-94ac-cdf67d3fa94a", key: "riveroaksmn", name: "River Oaks of Minnesota"}}
                collection="RiverOaks-c1-v1"
                level1='Policies and Procedures'
                pageTitle="River Oaks Policies and Procedures"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Policies and Procedures</span>}
            />,
          },
          {
            path: 'Reports',
            auth: ['admin', 'RiverOaks'],
            element: <SearchHistoryReport />,
            children: [
              { path: '', element: <SearchHistoryHomePage /> },
            ]
          },
          {
            path: 'Reports/SearchHistory',
            auth: ['admin', 'RiverOaks'],
            element: <SearchHistoryReport />,
            children: [
              { path: '', element: <SearchHistoryHomePage /> },
            ]
          },
          
        ],
      },
    ],
  };
  
  export default Routes;
  