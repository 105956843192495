import { makeAutoObservable } from "mobx";
import { PermissionSet, Permission } from "./PermissionSets";
import { RoleName, ROLES } from "./Roles.ts";
import { Settings } from './Settings.ts';
import { v4 as uuid } from 'uuid';
import { getPermissionSettings } from "../db/firestore.ts";
//  {Feature: 'DocHub', PermissionSet: 'Library', Permission: 'Create library', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},

class RolePermissionSetting {
    id: string;
    value: boolean = false;
    createdOn: string = new Date().toISOString();
    createdBy: string = 'system';
    modifiedOn: string = new Date().toISOString();
    modifiedBy: string = 'system';

    constructor() {
        this.id = uuid();
    }
}

class PermissionSetting {

    id: string;
    permissionSetName: string;
    permissionName: string;

    command: string;
    operation: string;
    description: string;
    orgId: string = 'system';
    orgKey: string = '';

    
    admin: RolePermissionSetting = new RolePermissionSetting();
    owner: RolePermissionSetting = new RolePermissionSetting();
    approver: RolePermissionSetting = new RolePermissionSetting();
    editor: RolePermissionSetting = new RolePermissionSetting();
    contributor: RolePermissionSetting = new RolePermissionSetting();
    viewer: RolePermissionSetting = new RolePermissionSetting();
    auditor: RolePermissionSetting = new RolePermissionSetting();
    guest: RolePermissionSetting = new RolePermissionSetting();
    noAccess: RolePermissionSetting = new RolePermissionSetting();

    constructor() {
        this.id = uuid();
        makeAutoObservable(this);
    }
}

class PermissionSettings {
    items: PermissionSetting[] = [];

    constructor(SettingsManager: Settings) {
        load(this, SettingsManager);
        // loadFromFirestore(this, SettingsManager);
        makeAutoObservable(this);
    }

    getById(id: string): PermissionSetting {
        return this.items.find(p => p.id === id);
    }

    getByPermissionSetName(permissionSetName: string): PermissionSetting[] {
        return this.items.filter(p => p.permissionSetName === permissionSetName);
    }

    getByPermissionName(permissionName: string): PermissionSetting[] {
        return this.items.filter(p => p.permissionName === permissionName);
    }
}

async function loadFromFirestore(instance: PermissionSettings, SettingsManager: Settings){

    let permissionSettings = await getPermissionSettings('system');    
    instance.items = permissionSettings.items;
}

/*
 This converts the permissionSets and roles into a list of permission settings
 for the Roles Configuration Page. Role settings are bound to permission settings to keep 
 everything automatically in sync.

 PermissionSettings looks like this:

    {PermissionSet: 'Library', Permission: 'Create library', Admin: true, Owner: true, Approver: true, Editor: true, Contributor: true, Viewer: true, Auditor: true, Guest: true},
*/
function load(instance: PermissionSettings, settings: Settings){

    
    const permissionSets = settings.permissionSets;
    const roles = settings.roles;

    for(const permissionSet of permissionSets.items){        
        if(permissionSet.items == null) continue;
        
        for(const permission of permissionSet.items){

            const permissionSetting = new PermissionSetting();
            permissionSetting.permissionSetName = permissionSet.name;
            permissionSetting.permissionName = permission.name;
            permissionSetting.command = permission.command;
            permissionSetting.operation = permission.operation;
            permissionSetting.description = permission.description;

            for(const role of roles.items){
                    
                let rolePermissionSet = role.permissions?.items?.find(p => p.name === permissionSet.name);
                if(rolePermissionSet == null) {
                    console.log(`Role '${role.name}' does not have permissionSet '${permissionSet.name}'`);
                    continue;
                }

                let rolePermission = rolePermissionSet.items?.find(p => p.name === permission.name);
                if(rolePermission == null) {
                    console.log(`Role '${role.name}' does not have permission '${permission.name}'`);
                    continue;
                }

            } // for roles.items

            instance.items.push(permissionSetting);
            
        } // for permissionSet.items
    } // for permissionSets.items
} // function load


export default PermissionSettings;