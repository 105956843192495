import React, {lazy} from 'react';
const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp'));
const SearchHistoryReport = lazy(() => import('../modules/reports/SearchHistory/Report'));
const SearchHistoryHomePage = lazy(() => import('../modules/reports/SearchHistory/pages/HomePage'));


const Routes = {
    settings: {
      layout: {
        config: {},
      },
    },
    routes: [
      {
        path: 'PeacefulLivingCare',
        element: <Layout />,
        auth: ['admin', 'peaceful_living'],
        children: [
          {
            path: '',
            auth: ['admin', 'peaceful_living'],
            element: <ChatApp
              org={{id: "aaff062a-358b-48d1-9357-a0776b80cfe4", key: "PeacefulLivingCare", name: "Peaceful Living Care"}}
              collection="pl-v2"
              pageTitle="Peaceful Living Resources"
              pageDescription="BrightSource AI Assistant"
              chatHeader={<span>Peaceful Living BrightSources</span>}
            />,
          },
          {
            path: 'FederalResources',
            auth: ['admin', 'peaceful_living'],
            element: <ChatApp
                org={{id: "aaff062a-358b-48d1-9357-a0776b80cfe4", key: "PeacefulLivingCare", name: "Peaceful Living Care"}}
              collection="pl-v2"
              level1='Federal Rules and Regs'
              pageTitle="Federal Rules and Regulations"
              pageDescription="BrightSource AI Assistant"
              chatHeader={<span>Federal Rules and Regulations</span>}
            />,
          },
          {
            path: 'MN',
            auth: ['admin', 'peaceful_living'],
            element: <ChatApp
                org={{id: "aaff062a-358b-48d1-9357-a0776b80cfe4", key: "PeacefulLivingCare", name: "Peaceful Living Care"}}
              collection="pl-v2"
              level1='MN'
              pageTitle="Peaceful Living Minnesota Resources"
              pageDescription="BrightSource AI Assistant"
              chatHeader={<span>MN Resources</span>}
            />,
          },
          {
            path: 'WI',
            auth: ['admin', 'peaceful_living'],
            element: <ChatApp
                org={{id: "aaff062a-358b-48d1-9357-a0776b80cfe4", key: "PeacefulLivingCare", name: "Peaceful Living Care"}}
              collection="pl-v2"
              level1='WI'
              pageTitle="Peaceful Living Wisconsin Resources"
              pageDescription="BrightSource AI Assistant"
              chatHeader={<span>Wisconsin Resources</span>}
            />,
          },
          {
            path: 'HR',
            auth: ['admin', 'peaceful_living'],
            element: <ChatApp
                org={{id: "aaff062a-358b-48d1-9357-a0776b80cfe4", key: "PeacefulLivingCare", name: "Peaceful Living Care"}}
              collection="pl-v2"
              level1='HR'
              pageTitle="Peaceful Living HR Resources"
              pageDescription="BrightSource AI Assistant"
              chatHeader={<span>HR</span>}
            />,
          },
          {
            path: 'Training',
            auth: ['admin', 'peaceful_living'],
            element: <ChatApp
                org={{id: "aaff062a-358b-48d1-9357-a0776b80cfe4", key: "PeacefulLivingCare", name: "Peaceful Living Care"}}
              collection="pl-v2"
              level1='Training'
              pageTitle="Peaceful Living Training"
              pageDescription="BrightSource AI Assistant"
              chatHeader={<span>Training</span>}
            />,
          },
          {
            path: 'Reports',
            auth: ['admin', 'peaceful_living'],
            element: <SearchHistoryReport />,
            children: [
              { path: '', element: <SearchHistoryHomePage /> },
            ]
          },
          {
            path: 'Reports/SearchHistory',
            auth: ['admin', 'peaceful_living'],
            element: <SearchHistoryReport />,
            children: [
              { path: '', element: <SearchHistoryHomePage /> },
            ]
          },
          
        ],
      },
    ],
  };
  
  export default Routes;
  