import '@mock-api';
import { useState, useContext} from 'react';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
// import { selectUser } from 'app/store/userSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import settingsConfig from 'app/configs/settingsConfig';
import withAppProviders from './withAppProviders';
// import FuseAuthorization from '@fuse/core/FuseAuthorization';
// import Auth from './auth/services/firebase/AuthContext';
// import { AuthProvider as OriginalAuthProvider } from './auth/services/firebase/FirebaseAuthProvider';
import AuthProvider from './AuthProvider';
import SecureRouter from './SecureRouter';
import userflow from 'userflow.js'
import AppContext from './AppContext';
import { TrainingAndSurveys } from './modules/TrainingAndSurveys/TrainingAndSurveys';
import UserActivity from './UserActivity';
import ACT from './UserActivities';
// import { RootStore, StoreProvider } from './store/RootStore.ts';

window.UserActivity = new UserActivity();
window.ACT = ACT;

// console.log(`Userflow ${process.env.REACT_APP_USERFLOW}`)
userflow.init(process.env.REACT_APP_USERFLOW);

/*
function saveConfirmation () {
  console.log('flush log 1')

  setTimeout(() => {
    console.log('flush log 2')
  }, 100);
}
function saveBeforeUnload(event){
  event.preventDefault();
  saveConfirmation ();
  return "Are you sure you want to exit?";
}
*/

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

// const rootStore = new RootStore();

function App() {
  const [ currentUser, setCurrentUser ] = useState();
  const [ currentOrg, setCurrentOrg ] = useState();
  const { routes } = useContext(AppContext);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);

  let appState = {currentUser, setCurrentUser, currentOrg, setCurrentOrg }

  formatUserflow();

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <FuseTheme theme={mainTheme} direction={langDirection}>
        {/* <StoreProvider value={rootStore}> */}
          <AuthProvider  {...appState} >
            <BrowserRouter>
              <SecureRouter currentUser={currentUser} routes={routes}>
              <SnackbarProvider
                  maxSnack={5}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  classes={{
                    containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                  }}
                >
                  <>
                    <TrainingAndSurveys />
                    <FuseLayout layouts={themeLayouts} />
                  </>

              </SnackbarProvider>
            </SecureRouter>
            </BrowserRouter>
          </AuthProvider>
        {/* </StoreProvider> */}
      </FuseTheme>
    </CacheProvider>
  );
}

function formatUserflow() {

  let iframe, doc;

  // hide userflow text
  try {
    let uf = document.getElementById('userflow-ui');
    iframe = uf.getElementsByTagName('iframe');
    doc = iframe[0].contentWindow.document;

    let ad = doc.getElementsByClassName('userflowjs-bubble-made-with-userflow__absolute');
    let txt = ad[0].getElementsByTagName('div');
    let svg = ad[0].getElementsByTagName('svg');
    txt[0].style.color = '#ffffff';
    //svg[0].style.fill =  '#ffffff';
    svg[0].style.height =  '0px';
    svg[0].style.width =  '0px';
  }
  catch(ex){

  }

  // Match "BrightSource Tutorials" to Brand
  try {

    if(doc != null){
      let ad2 = doc.getElementsByClassName('userflowjs-text-align-center');
      ad2[0].childNodes[0].style.color = 'rgb(54, 133, 146)'; // #368592 !important
    }
  }
  catch(ex){
    //console.log();
    let msg = ex.message;
  }

  // Match Checkmarks to Brand
  try {

    if(doc != null){

      let ad3 = doc.getElementsByClassName('userflowjs-bubble-frame-root');
      let div = ad3[0].getElementsByClassName('userflowjs-bubble-task__checkmark');

      for(let i=0; i<div.length;i++){
        let item = div[i];
        if(item.childNodes.length > 0){
          item.style.backgroundColor = 'rgb(43, 126, 140)'; // #2b7e8c'
          item.childNodes[0].style.backgroundColor = 'rgb(43, 126, 140)';
        }
      }
    }
  }
  catch(ex){
    //console.log();
    let msg = ex.message;
  }

  setTimeout(() => {
    formatUserflow();
  }, 1000);
}
export default withAppProviders(App)();
