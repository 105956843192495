'use client';

import { styled } from '@mui/material/styles';
import PageLayout from '@fuse/core/FusePageSimple';
import { Outlet } from "react-router-dom";
import Header from './Header.tsx';
import LeftSidebar from './LeftSidebar.tsx';
import RightSidebar from './RightSidebar.tsx';
import DefaultPage from './Dashboard/Page.js';
import useSettingsContext from '../../context.js';

// Example: https://fuse-react-vitejs-demo.fusetheme.com/documentation/user-interface/page-layouts/carded/with-sidebars/normal-scroll

const Root = styled(PageLayout)(({ theme }) => ({
	'& .FusePageSimple-header': {
		backgroundColor: theme.palette.background.paper,
		borderBottomWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.divider
	},
	'& .FusePageSimple-toolbar': {},
	'& .FusePageSimple-content': {},
	'& .FusePageSimple-sidebarHeader': {},
	'& .FusePageSimple-sidebarContent': {}
}));

/**
 * The CardedWithSidebarsNormalScroll page.
 */
function SettingsLayout() {

	const {isLeftSidebarOpen, setIsLeftSidebarOpen, isRightSidebarOpen, setIsRightSidebarOpen} = useSettingsContext();

	return (
		<Root
			header={
				<Header
					leftSidebarToggle={() => {
						setIsLeftSidebarOpen(!isLeftSidebarOpen);
					}}
					rightSidebarToggle={() => {
						setIsRightSidebarOpen(!isRightSidebarOpen);
					}}
				/>
			}
			content={<Outlet />}
			leftSidebarOpen={isLeftSidebarOpen}
			leftSidebarOnClose={() => {
				setIsLeftSidebarOpen(false);
			}}
			leftSidebarContent={<LeftSidebar />}			
			rightSidebarOpen={isRightSidebarOpen}
			rightSidebarOnClose={() => {
				setIsRightSidebarOpen(false);
			}}
			rightSidebarContent={<RightSidebar />}
			scroll="normal"
		/>
	);
}

export default SettingsLayout;
