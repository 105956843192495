import { makeAutoObservable } from "mobx";
// import { DepartmentCategory, DepartmentCategories } from "./DepartmentCategory";

export class Department {
    id: string;
    name: string;
    // category: string; // lookup to DepartmentCategory
    staff: number;    
    locations: string;    
    
    constructor() {
        makeAutoObservable(this);
    }
}

export class Departments {
    items: Department[] = [];

    constructor() {
        loadDepartments(this);
        makeAutoObservable(this);
    }
}

/**
 * This is temporary and needs to change to a real API call
 */
function loadDepartments(departments: Departments) {

    departments.items = [
        { id: '1', name: 'Administration & Management', staff: 0, locations: 'ALL' },
        { id: '2', name: 'Nursing & Clinical Care', staff: 0, locations: 'ALL' },
        { id: '3', name: 'Rehabilitation Services', staff: 0, locations: 'ALL' },
        { id: '4', name: 'Medical & Pharmacy Services', staff: 0, locations: 'ALL' },
        { id: '5', name: 'Social Services & Resident Advocacy', staff: 0, locations: 'ALL' },
        { id: '6', name: 'Activities & Recreation', staff: 0, locations: 'ALL' },
        { id: '7', name: 'Dietary & Nutrition Services', staff: 0, locations: 'ALL' },
        { id: '8', name: 'Environmental Services', staff: 0, locations: 'ALL' },
        { id: '9', name: 'Security & Safety', staff: 0, locations: 'ALL' },
        { id: '10', name: 'Information Technology (IT) & Compliance', staff: 0, locations: 'ALL' }
    ]
    /*
    departments.items = [
        // 1. Administration & Management
        {
            department: 'Executive Director / Administrator',
            category: '1',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Assistant Administrator',
            category: '1',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Business Office / Finance',
            category: '1',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Human Resources (HR)',
            category: '1',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Admissions & Marketing',
            category: '1',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },

        // 2. Nursing & Clinical Care
        {
            department: 'Director of Nursing (DON)',
            category: '2',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Assistant Director of Nursing (ADON)',
            category: '2',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Registered Nurses (RNs)',
            category: '2',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Licensed Practical Nurses (LPNs)',
            category: '2',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Certified Nursing Assistants (CNAs)',
            category: '2',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Infection Control / Wound Care Nurses',
            category: '2',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Medication Aides / Technicians',
            category: '2',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },

        // 3. Rehabilitation Services
        {
            department: 'Physical Therapy (PT)',
            category: '3',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Occupational Therapy (OT)',
            category: '3',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Speech-Language Pathology (SLP)',
            category: '3',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },

        // 4. Medical & Pharmacy Services
        {
            department: 'Medical Director',
            category: '4',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Attending Physicians',
            category: '4',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Nurse Practitioners (NPs) / Physician Assistants (PAs)',
            category: '4',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Consulting Specialists (e.g., psychiatry, podiatry)',
            category: '4',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Pharmacy Services',
            category: '4',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },

        // 5. Social Services & Resident Advocacy
        {
            department: 'Social Workers',
            category: '5',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Case Managers',
            category: '5',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Resident Council / Family Liaison',
            category: '5',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },

        // 6. Activities & Recreation
        {
            department: 'Activities Director',
            category: '6',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Recreational Therapists',
            category: '6',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Volunteers',
            category: '6',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },

        // 7. Dietary & Nutrition Services
        {
            department: 'Dietary Manager',
            category: '7',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Registered Dietitian (RD)',
            category: '7',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Cooks & Kitchen Staff',
            category: '7',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },

        // 8. Environmental Services
        {
            department: 'Housekeeping / Janitorial Services',
            category: '8',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Laundry Services',
            category: '8',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Maintenance & Facilities Management',
            category: '8',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },

        // 9. Security & Safety
        {
            department: 'Security Officers',
            category: '9',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Emergency Preparedness & Safety Team',
            category: '9',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },

        // 10. Information Technology (IT) & Compliance
        {
            department: 'IT Support & EHR Management',
            category: '10',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'Compliance & Risk Management',
            category: '10',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        },
        {
            department: 'HIPAA & Regulatory Affairs',
            category: '10',
            staff: 0,
            residents: 0,
            locations: 'ALL',
            assignments: 0
        }
    ];
    */
}
