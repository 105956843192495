import { useContext } from 'react';
import { createContext} from 'react';

export const Context = createContext({});

export default function useSettingsContext() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Settings Context must be used within the Settings Provider');
  }
  return context;
}